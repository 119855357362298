<template>
    <div>
      <v-container fluid class="mt-2">
        <v-row>
          <v-col cols="12" class="mb-0 mt-0">
            <v-breadcrumbs :items="breadcumbs" class="mb-1 pb-1">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item mb-0"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-card class="rounded-l" outlined>
                <v-card-text class="ma-0 pb-0">
                    <v-container fluid class="ma-0 pa-2">
                        <v-row>
                            <v-col class="col-12 pb-0 pt-0" sm="6" md="2">
                                <h6 class="blue-lcd mb-1">Company </h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="addCompanyIDModel"
                                    :items="addCompanyIDList"
                                    item-value="Company"
                                    item-text="description"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    disabled
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12 pb-0 pt-0" sm="6" md="2">
                                <h6 class="blue-lcd mb-1">Department </h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="department"
                                    :items="departments"
                                    item-value="dep_id"
                                    item-text="dept_name"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => getEmployee(event)"
                                    disabled
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12 pb-0 pt-0" sm="6" md="2">
                                <h6 class="blue-lcd mb-1">Division </h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="divisi"
                                    :items="divisions"
                                    item-value="division_id"
                                    item-text="division_name"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => [getEmployee(event), getSection(event)]"
                                    :disabled="disabled_divisi"
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12 pb-0 pt-0" sm="6" md="2">
                                <h6 class="blue-lcd mb-1">Section </h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="section"
                                    :items="sections"
                                    item-value="section_id"
                                    item-text="sec_name"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => [getEmployee(event), getGroupSection(event)]"
                                    :disabled="disabled_section"
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12 pb-0 pt-0" sm="6" md="2">
                                <h6 class="blue-lcd mb-1">Group Section </h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="group_section"
                                    :items="group_sections"
                                    item-value="group_id"
                                    item-text="group_name"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => getEmployee(event)"
                                    :disabled="disabled_sec_group"
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12 pb-0 pt-0" sm="6" md="2">
                                <h6 class="blue-lcd mb-1">Employee </h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="employee"
                                    :items="employees"
                                    item-value="idemployee"
                                    :item-text="item => item.idemployee +' - '+ item.name"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12 pb-0" sm="6" md="2">
                                <h6 class="blue-lcd mb-1">Start Date </h6>
                                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field solo v-model="dateStartModel" readonly v-bind="attrs" v-on="on" dense clearable append-icon="mdi-calendar-check" class="pa-0 border-12"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col class="col-12 pb-0" sm="6" md="2">
                                <h6 class="blue-lcd mb-1">End Date </h6>
                                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field solo v-model="dateEndModel" readonly v-bind="attrs" v-on="on" dense clearable append-icon="mdi-calendar-check" class="pa-0 border-12"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="4" class="col-12 pt-0 mt-0">
                                <v-btn class="border-12 mt-9 mr-3" style="padding: 19px;" small elevation="2" @click="clear()">Clear</v-btn>
                                <v-btn class="border-12 mt-9" style="padding: 19px;" small color="primary" elevation="2" @click="populateAbsenceList()">Search <v-icon class="ml-3">mdi-magnify</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-tabs
        v-model="currentTabIndex"
        color="#e8ecef"
        class="round_top col-sm-no-padding ml-3"
        icons-and-text
        show-arrows
      >
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
              v-for="(tab, index) in tabs"
              :key="index"
              :href="'#tab-'+tab.aprv_num"
              active-class="active-tab"
              @click="populateAbsenceList(tab.aprv_num)"
          >
            Approve {{ tab.aprv_num }}
          </v-tab>

      </v-tabs>
      <v-tabs-items v-model="currentTabIndex">
        <v-tab-item
          v-for="(tab, index) in tabs"
          :key="index"
          :value="'tab-'+tab.aprv_num"
        >
          <ComponentOvertime :aprv="tab.aprv_num" :itemLists.sync="itemLists" @displayData="populateAbsenceList($event)"></ComponentOvertime>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </template>
  <script>
  import { mapState } from 'vuex'
  import {user} from "@/backend-api/user"
  import {appl_constant} from "@/backend-api/appl_constant"
  import {employee} from "@/backend-api/employee"
  import {department} from "@/backend-api/department"
  import {divisi} from "@/backend-api/divisi"
  import {section} from "@/backend-api/section"
  import {group_section} from "@/backend-api/group_section"
  import ComponentOvertime from "@/components/ComponentOvertime.vue"

  export default {
    components: {
        ComponentOvertime
    },
    data: () => ({
        breadcumbs: [
            {
                text: 'Home',
                disabled: false,
                href: '/employee',
            },
            {
                text: 'Overtime',
                disabled: true,
            }
        ],
        headers: [
            { text: 'TRANS DATE', value: 'tr_date', align: 'left', class: 'primary--text blue lighten-5' },
            { text: 'ID EMPLOYEE', value: 'idemployee', align: 'left', class: 'primary--text blue lighten-5' },
            { text: 'EMPLOYEE', value: 'employee', align: 'left', class: 'primary--text blue lighten-5' },
            { text: 'REASON', value: 'ijin_descr', align: 'left', class: 'primary--text blue lighten-5' },
            { text: 'ATTACHMENT', value: 'file_name', align: 'center', class: 'primary--text blue lighten-5' },
            { text: 'REMARK', value: 'keterangan', align: 'left', class: 'primary--text blue lighten-5' },
            { text: 'START DATE', value: 'dt_from', align: 'left', class: 'primary--text blue lighten-5' },
            { text: 'UNTIL DATE', value: 'dt_to', align: 'left', class: 'primary--text blue lighten-5' },
            // { text: 'APRV 1', value: 'aprv', align: 'left', class: 'primary--text blue lighten-5' },
            // { text: 'APRV 2', value: 'aprv2', align: 'left', class: 'primary--text blue lighten-5' },
            // { text: 'APRV 3', value: 'aprv3', align: 'left', class: 'primary--text blue lighten-5' },
        ],
        headersColumn:{
        'DATE': 'tr_date',
        'REASON': 'ijin_descr',
        'REMARK': 'keterangan',
        'START DATE': 'dt_from',
        'UNTIL DATE': 'dt_to',
        'Stat': 'stat',
        },
        itemLists: [],
        searchItem: '',
        addCompanyIDModel: '',
        addCompanyIDList: [],
        addIjinModel: '',
        addIjinList: [],
        addIjinLoading: false,
        dateStartModal: false,
        dateStartModel: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateStartFormatted: '',
        dateEndModal: false,
        dateEndModel: '',
        dateEndFormatted: '',
        departments: [],
        department: '',
        divisi: '',
        divisions: [],
        section: '',
        sections: [],
        group_section: '',
        group_sections: [],
        employees: [],
        employee: '',
        max_aprv: 1,
        tr_types: [],
        tr_type: 'SPL',
        aprv_num: 0,
        detailItem: null,
        dialogShowImage: false,
        imageList: [],
        dialog_aprv:false,
        disabled_divisi: false,
        disabled_section: false,
        disabled_sec_group: false,
        tabs: [],
        currentTabIndex:'',
        approvals: []
    }),
    async mounted() { 
      this.$store.dispatch('setOverlay', true)
      await this.initialize()
      await this.getDepartment()
      await this.getApplConstant()
      await this.populateAbsenceList(this.tabs[0].aprv_num)
      this.$store.dispatch('setOverlay', false)
    },
    methods:{
        tr_datatable(item) {
            var rowClass = 'tr_datatable'
            return rowClass;
        },
        clear(){
          if(this.disabled_divisi == false && this.disabled_section == false && this.disabled_sec_group == false){
            this.divisi = ''
            this.section = ''
            this.group_section = ''
            this.sections = []
            this.group_sections = []
          } else if (this.disabled_divisi == true && this.disabled_section == false && this.disabled_sec_group == false){
            this.section = ''
            this.group_section = ''
            this.group_sections = []
          } else if (this.disabled_divisi == true && this.disabled_section == true && this.disabled_sec_group == false){
            this.group_section = ''
            this.group_sections = []
          }
          this.dateStartModel = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.dateEndModel = ''
        },
        async initialize(){
            const respData = await user.fetchCompany(`?id=${this.$store.state.user.id}`, null, false, false, false) 
            if (respData.status === 200) {
                this.addCompanyIDModel = this.$store.state.user.employee.company_id
                this.addCompanyIDList = respData.data.entity
                this.addIjinList = respData.data.ijin
            }
        },
        async getApplConstant(){
          var reqBody = {
            key_id: 'TR_TYPE',
            // str3: this.$store.state.user.employee.company_id
          }

          const respData = await appl_constant.index('', reqBody, false, false, false)

          if (respData.status === 200) {
            this.tr_types = respData.data
          }
        },
        async getDepartment(){
          var reqBody = {
            company_id: this.$store.state.user.employee.company_id,
            idemployee:  this.$store.state.user.id
          }

          const respData = await department.deptViewAprv('', reqBody, false, false, false)

          if (respData.status === 200) {
            this.department = respData.data.length > 0 ? respData.data[0].dep_id : ''
            this.departments = respData.data
            await this.maxApprove(this.department)
            await this.getDivisi(this.department)
            await this.getEmployee(this.department)
          }
        },
        async getEmployee(event){
          if (event == '' || event == null) {
            this.employee = ''
          } else {
            var reqBody = {
              entity_id: this.$store.state.user.employee.company_id,
              dept_id:  this.department ? this.department : '',
              division_id:  this.divisi ? this.divisi : '',
              section_id:  this.section ? this.section : '',
              group_id:  this.group_section ? this.group_section : ''
            }

            const respData = await employee.index('', reqBody, false, false, false)

            if (respData.status === 200) {
              this.employees = respData.data
            }
          }
        },
        async maxApprove(event){
          if (event == '' || event == null) {
            this.max_aprv = 1
          } else {
            
            var reqBody = {
              company: this.$store.state.user.employee.company_id.trim(),
              tr_type: this.tr_type,
              dep_id: this.department ? this.department.trim() : '',
              idemployee:  this.$store.state.user.id
            }

            await this.checkUserAprvNum()
            const respData = await user.cekUserApproveNum('', reqBody, false, false, false)

            if (respData.status === 200) {
              // console.log(respData.data);
              // this.aprv_num = respData.data.aprv_num ? Number(respData.data.aprv_num) : 0
              if (respData.data.aprv_level == 'DEPARTMENT') {
                this.disabled_divisi = false
                this.disabled_section = false
                this.disabled_sec_group = false
              } else if(respData.data.aprv_level == 'DIVISION'){
                this.divisi = respData.data.div_id
                this.disabled_divisi = true
                this.disabled_section = false
                this.disabled_sec_group = false
              } else if(respData.data.aprv_level == 'SECTION'){
                this.section = respData.data.sec_id
                this.disabled_divisi = true
                this.disabled_section = true
                this.disabled_sec_group = false
                await this.getSection(this.section)
              } else if(respData.data.aprv_level == 'GROUPSECTION'){
                this.group_section = respData.data.gsc_id
                this.disabled_divisi = true
                this.disabled_section = true
                this.disabled_sec_group = true
                await this.getGroupSection(this.group_section)
              }
            }
          }
          
        },
        async populateAbsenceList(aprv_num){
          this.itemLists = []
          this.$store.dispatch('setOverlay', true)

          if(this.department == null || this.department == ''){
              Swal.fire({
                      text: 'Please Select Department !',
                      icon: 'warning',
                  })
            this.$store.dispatch('setOverlay', false)
            return false
          }

          if(this.tr_type == null || this.tr_type == ''){
              Swal.fire({
                      text: 'Please Select Tr Type !',
                      icon: 'warning',
                  })
            this.$store.dispatch('setOverlay', false)
            return false
          }

          if(this.dateStartModel == null || this.dateStartModel == ''){
              Swal.fire({
                      text: 'Please Select Start Date Type !',
                      icon: 'warning',
                  })
            this.$store.dispatch('setOverlay', false)
            return false
          }
          var approve_tab = this.currentTabIndex.substr(4, 6)
          console.log(approve_tab);
          var reqBody = {
            id: this.employee ? this.employee : '',
            company: this.$store.state.user.employee.company_id,
            startDate: this.dateStartModel ? new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"") : '',
            endDate: this.dateEndModel ? new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"") : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace(/-/g,""),
            tr_type: this.tr_type ? this.tr_type : 'SPL',
            dept_id: this.department ? this.department : '',
            division_id: this.divisi ? this.divisi : '',
            section_id: this.section ? this.section : '',
            group_id: this.group_section ? this.group_section : '',
            not_approve: aprv_num ? aprv_num : Number(approve_tab),
            id_employee_aprv: this.$store.state.user.id
          }


          // if (Number(approve_tab) != 1) {
          //   await this.getApproval(approve_tab)
          // } else {
            const respData = await user.getDataApprove('', reqBody, false, false, false)

            if (respData.status === 200) {            
              this.itemLists = respData.data.result
              this.$store.dispatch('setOverlay', false)
            }
          // }

         
        },
        async getApproval(apprv){
          console.log(apprv);
          this.approvals = []

          if(this.department == null || this.department == ''){
              Swal.fire({
                      text: 'Please Select Department !',
                      icon: 'warning',
                  })
            this.$store.dispatch('setOverlay', false)
            return false
          }

          if(this.tr_type == null || this.tr_type == ''){
              Swal.fire({
                      text: 'Please Select Tr Type !',
                      icon: 'warning',
                  })
            this.$store.dispatch('setOverlay', false)
            return false
          }

          if(this.dateStartModel == null || this.dateStartModel == ''){
              Swal.fire({
                      text: 'Please Select Start Date Type !',
                      icon: 'warning',
                  })
            this.$store.dispatch('setOverlay', false)
            return false
          }

          var reqBody = {
            id: this.employee ? this.employee : '',
            company: this.$store.state.user.employee.company_id,
            startDate: this.dateStartModel ? new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"") : '',
            endDate: this.dateEndModel ? new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"") : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace(/-/g,""),
            tr_type: this.tr_type ? this.tr_type : 'SPL',
            dept_id: this.department ? this.department : '',
            division_id: this.divisi ? this.divisi : '',
            section_id: this.section ? this.section : '',
            group_id: this.group_section ? this.group_section : '',
            not_approve: apprv,
          }

          if (Number(apprv) != 1) {
            this.$store.dispatch('setOverlay', true)
            const respData = await user.index('', reqBody, false, false, false)

            if (respData.status === 200) {
              this.approvals = respData.data.result
              this.$store.dispatch('setOverlay', false)

            }
          }
        },
        async checkUserAprvNum(){
          var reqBody = {
            company: this.$store.state.user.employee.company_id.trim(),
            tr_type: this.tr_type,
            dep_id: this.department ? this.department.trim() : '',
            idemployee:  this.$store.state.user.id
          }

          const respData = await user.cekUserApproveNums('', reqBody, false, false, false)

          if (respData.status === 200) {
            this.tabs = respData.data
            
          }
        },
        async getDivisi(event){
          if (event == '' || event == null) {
            this.divisi = ''
            this.section = ''
            this.group_section = ''
            this.employee = ''
          } else {
            var reqBody = {
              entity_id: this.$store.state.user.employee.company_id.trim(),
              dept_id: this.department ? this.department.trim() : '',
              division_id: this.divisi ? this.divisi : ''
            }

            const respData = await divisi.index('', reqBody, false, false, false)

            if (respData.status === 200) {
              this.divisions = respData.data
            }
          }
        },
        async getSection(event){
          if (event == '' || event == null) {
            this.section = ''
            this.group_section = ''
            this.employee = ''
          } else {
            var reqBody = {
              entity_id: this.$store.state.user.employee.company_id.trim(),
              dept_id: this.department ? this.department.trim() : '',
              division_id: this.divisi ? this.divisi : '',
              section_id: this.section ? this.section : ''
            }

            const respData = await section.index('', reqBody, false, false, false)

            if (respData.status === 200) {
              this.sections = respData.data
            }
          }
        },
        async getGroupSection(event){
          if (event == '' || event == null) {
            this.group_section = ''
            this.employee = ''
          } else {
            var reqBody = {
              entity_id: this.$store.state.user.employee.company_id.trim(),
              dept_id: this.department ? this.department.trim() : '',
              division_id: this.divisi ? this.divisi : '',
              section_id: this.section ? this.section : ''
            }

            const respData = await group_section.index('', reqBody, false, false, false)

            if (respData.status === 200) {
              this.group_sections = respData.data
            }
          }
        },
        async showImage(id){
          this.dialogShowImage = true

          this.imageList = []

          var reqBody = {
            'id': id
          }

          const respData = await user.getImages('', reqBody, false, false, false)

          if (respData.status === 200) {
            // console.log(respData);
            for (let index = 0; index < respData.data.length; index++) {
                const element = respData.data[index];
                this.imageList.push(process.env.VUE_APP_URL + '/img/hrd/emp/izin/' + element.file_name)
            }
          }
        },
        showAproveAbsence(item){
          this.detailItem = item
          this.dialog_aprv = true
        },
        async approved(){
          this.dialog_aprv = false
          var reqBody = {
            company_id: this.detailItem ? this.detailItem.company_id : '',
            tr_type: this.detailItem ? this.detailItem.tr_type : '',
            doc_id: this.detailItem ? this.detailItem.doc_id : '',
            idemployee: this.detailItem ? this.detailItem.idemployee : '',
            id_aprv: this.$store.state.user.id,
            aprv_num: this.aprv_num
          }
          this.$store.dispatch('setOverlay', true)

          const respData = await user.approve('', reqBody, false, false, false)

          if (respData.status === 200) {
            Swal.fire({
                text: 'Success Approve Absence',
                icon: 'success',
            })
            this.populateAbsenceList()
          }else {
            console.log(respData.data);
          }

          this.$store.dispatch('setOverlay', false)

        },
        async rejected(){
          this.dialog_aprv = false
          var reqBody = {
            company_id: this.detailItem ? this.detailItem.company_id : '',
            tr_type: this.detailItem ? this.detailItem.tr_type : '',
            doc_id: this.detailItem ? this.detailItem.doc_id : '',
            idemployee: this.detailItem ? this.detailItem.idemployee : '',
            id_aprv: this.$store.state.user.id,
            aprv_num: this.aprv_num
          }

          this.$store.dispatch('setOverlay', true)

          const respData = await user.reject('', reqBody, false, false, false)

          if (respData.status === 200) {
            Swal.fire({
                text: 'Success Reject Absence',
                icon: 'success',
            })
            this.populateAbsenceList()

          } else {
            console.log(respData.data);
          }

          this.$store.dispatch('setOverlay', false)
        }
    },
    watch: {
    }
  }
  </script>
    
  <style scoped>
  .tr_datatable{
    background-color: #F5F7F8 !important;
  }
  .sausage-dog-animation {
  height: 25rem;
  max-height: 80vh;
  max-width: 80vw;
  }
  
  .ear,
  .closed-eye,
  .lean,
  .front-legs,
  .leg,
  .head,
  .tail,
  .tail-blur,
  .shadow {
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  }
  
  .ball,
  .ball-decoration,
  .ball-sound {
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  }
  
  .ball {
  animation-name: squishBall;
  transform: matrix(
    1.0951654,
    0.52195853,
    -0.52866476,
    1.2371611,
    208.27138,
    -632.28196
  );
  }
  
  @keyframes squishBall {
  0%,
  50%,
  72%,
  80%,
  92%,
  100% {
    d: path(
      "m 930.80242,477.19065 c -13.05851,3.00801 -24.77881,15.97694 -26.81485,30.67673 -2.03603,14.69979 5.61282,31.12503 15.54844,43.47271 9.93563,12.34768 22.15522,20.61485 36.12779,24.29677 13.97258,3.68192 29.69644,2.77869 42.91157,-2.81649 13.21513,-5.59518 23.92113,-15.88139 30.65943,-28.14324 6.7383,-12.26185 9.5079,-26.49697 5.7369,-38.5172 -3.771,-12.02022 -14.0825,-21.82316 -25.5603,-24.46605 -11.47786,-2.6429 -24.12011,1.87601 -37.63861,0.6591 -13.5185,-1.21691 -27.91187,-8.17033 -40.97037,-5.16233 z"
    );
    transform: matrix(
      1.0951654,
      0.52195853,
      -0.52866476,
      1.2371611,
      208.27138,
      -632.28196
    );
  }
  65%,
  85% {
    d: path(
      "m 932.4158,479.26229 c -14.67189,0.93637 -26.39219,13.9053 -28.42823,28.60509 -2.03603,14.69979 5.61282,31.12503 15.94228,38.97676 10.32947,7.85173 23.3365,7.12856 34.39281,10.90173 11.0563,3.77318 20.16082,12.04166 33.72906,12.76579 13.56828,0.72414 31.59878,-6.09489 42.72528,-18.16177 11.1265,-12.06689 15.3474,-29.37891 10.9229,-43.93943 -4.4245,-14.56052 -17.4943,-26.36654 -28.8312,-27.22343 -11.337,-0.85689 -20.93852,9.23684 -34.54977,8.73602 -13.61125,-0.50082 -31.23124,-11.59712 -45.90313,-10.66076 z"
    );
    transform: matrix(
      1.0951654,
      0.52195853,
      -0.52866476,
      1.2371611,
      208.27138,
      -642.28196
    );
  }
  }
  
  .ball-decoration {
  animation-name: ballDecorationAnimation;
  }
  
  @keyframes ballDecorationAnimation {
  0%,
  50%,
  72%,
  80%,
  92%,
  100% {
    d: path(
      "m 963.39546,597.71943 c 21.49913,-19.30313 4.80913,-64.80408 16.71919,-83.46282 16.19467,-25.37116 67.93925,-22.92156 89.43095,-47.68524"
    );
  }
  65%,
  85% {
    d: path(
      "m 978.40243,581.77452 c 21.49916,-19.30313 -15.82546,-51.20401 -3.9154,-69.86275 16.19467,-25.37116 63.71847,-19.16982 85.21017,-43.9335"
    );
  }
  }
  
  .ball-sound {
  animation-name: ballSound;
  visibility: hidden;
  }
  
  @keyframes ballSound {
  0%,
  60%,
  70%,
  80%,
  90%,
  100% {
    visibility: hidden;
    transform: translateY(0);
  }
  65%,
  67%,
  69%,
  85%,
  87%,
  89% {
    visibility: visible;
    transform: translateY(-3px);
  }
  66%,
  68%,
  86%,
  88% {
    visibility: visible;
    transform: translateY(3px);
  }
  }
  
  .ear {
  animation-name: moveEar;
  transform-origin: top center;
  transform-box: fill-box;
  }
  
  @keyframes moveEar {
  0%,
  12%,
  21%,
  31%,
  35%,
  100% {
    transform: rotateZ(0);
  }
  9%,
  19%,
  29% {
    transform: rotateZ(-5deg);
    transform: rotateZ(-10deg);
  }
  13%,
  23%,
  33% {
    transform: rotateZ(5deg);
    transform: rotateZ(10deg);
  }
  }
  
  .closed-eye {
  animation-name: closeEye;
  }
  
  @keyframes closeEye {
  0%,
  50%,
  100% {
    visibility: hidden;
  }
  10% {
    visibility: visible;
  }
  }
  
  .lean {
  animation-name: leanDown;
  transform-origin: center;
  }
  
  @keyframes leanDown {
  0%,
  50%,
  100% {
    transform: rotateZ(0) translateY(0);
  }
  60%,
  90% {
    transform: rotateZ(10deg) translateY(5%);
  }
  }
  
  .front-legs {
  animation-name: flexLegs;
  }
  
  @keyframes flexLegs {
  0%,
  50%,
  100% {
    transform: translateX(0);
  }
  60%,
  90% {
    transform: translateX(12%);
  }
  }
  
  .leg {
  animation-name: rotateLegs;
  transform-origin: bottom left;
  transform-box: fill-box;
  transform: translateX(16%) rotate(-10deg);
  }
  
  @keyframes rotateLegs {
  0%,
  50%,
  100% {
    transform: translateX(16%) rotate(-10deg);
  }
  60%,
  90% {
    transform: translateX(35%) rotate(-83deg);
  }
  }
  
  .head {
  animation-name: lookDown;
  transform-origin: top right;
  transform-box: fill-box;
  }
  
  @keyframes lookDown {
  0%,
  55%,
  100% {
    transform: rotateZ(0) translate(0, 0);
  }
  60%,
  90% {
    transform: rotateZ(5deg) translate(2.5%, 6%);
  }
  }
  
  .tail {
  animation-name: moveTail;
  transform-origin: bottom center;
  }
  
  @keyframes moveTail {
  0%,
  50%,
  90%,
  100% {
    d: path(
      "m 161.6285,568.63016 20.92664,-20.00034 C 151.50961,521.73829 14.092,504.432 14.092,504.432 c 0,0 128.8135,26.71916 147.5365,64.19816 z"
    );
  }
  64%,
  70%,
  76%,
  82% {
    d: path(
      "m 161.6285,568.63016 20.92664,-20.00034 C 151.50961,521.73829 77.565044,422.94078 77.565044,422.94078 c 0,0 65.340456,108.21038 84.063456,145.68938 z"
    );
  }
  60%,
  66%,
  72%,
  78%,
  84% {
    d: path(
      "m 161.6285,568.63016 20.92664,-20.00034 C 151.50961,521.73829 14.092,504.432 14.092,504.432 c 0,0 128.8135,26.71916 147.5365,64.19816 z"
    );
  }
  62%,
  68%,
  74%,
  80%,
  86% {
    d: path(
      "m 161.6285,568.63016 20.92664,-20.00034 C 151.50961,521.73829 159.419,390.74 159.419,390.74 c 0,0 -16.5135,140.41116 2.2095,177.89016 z"
    );
  }
  }
  
  .tail-blur {
  animation-name: tailBlur;
  transform-origin: bottom center;
  }
  
  @keyframes tailBlur {
  0%,
  59%,
  90%,
  100% {
    opacity: 0;
  }
  64%,
  70%,
  76%,
  82% {
    transform: rotate(-2deg);
    opacity: 0;
  }
  60%,
  66%,
  72%,
  78%,
  84% {
    opacity: 0.3;
  }
  62%,
  68%,
  74%,
  80%,
  86% {
    transform: rotate(2deg);
    opacity: 0;
  }
  }
  
  .shadow {
  animation-name: scaleShadow;
  transform-origin: center center;
  }
  
  @keyframes scaleShadow {
  0%,
  55%,
  100% {
    transform: scaleX(1) translateX(0);
  }
  60%,
  90% {
    transform: scaleX(1.1) translateX(4%);
  }
  }
    
    
  </style>